@use 'base';

@keyframes drop-frames {
  to {
    transform: none;
    opacity: 100%;
  }
}

nav.navbar {
  display: flex;
  background-color: base.$c-bg-accent;
  justify-content: flex-end;

  a {
    display: inline-block;
    padding: 1rem 2rem;
    text-decoration: none;
    color: #FFFFFF;
    font-size: 1.2em;
    opacity: .01;
    transform: translateY(-10px);
    transition: padding-bottom .2s, color .2s;
    animation: .2s base.$spawn-curve 0s 1 normal forwards drop-frames;
  }

  a:has(~ a:hover), a:hover ~ a {
    color: base.$c-text-light;
  }
}

@media screen and (max-width: 900px) {
  nav.navbar {
    justify-content: center;
  }
}
