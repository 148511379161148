@use 'base';
@use 'sass:math';

$arrow-width: 3px;
$arrow-half-width: math.div($arrow-width, 2);

.big-nav-btn {
  @extend %unhide;
  display: block;
  background-color: base.$c-bg-content;
  padding: 1rem;
  margin: 1rem auto 0 auto;
  max-width: 900px;
  width: calc(100% - 2rem);
  transform-origin: 50% 25%;
  transform: scale(0%);
  text-decoration: none;
  color: inherit;
  border: 1px solid base.$c-content-border;
  transition: box-shadow .2s;

  &::after, &::before {
    content: '';
    display: block;
    position: absolute;
    width: $arrow-width;
    border-radius: $arrow-half-width;
    height: 16px;
    right: 1rem;
    background-color: base.$c-text-accent;
    transition: background-color .2s;
  }

  &::after {
    bottom: calc(50% - $arrow-half-width);
    transform-origin: 50% calc(100% - $arrow-half-width);
    transform: rotate(315deg);
  }

  &::before {
    top: calc(50% - $arrow-half-width);
    transform-origin: 50% $arrow-half-width;
    transform: rotate(45deg);
  }

  h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
  }

  p {
    margin: 0;
  }
}

.big-nav-btn:hover {
  box-shadow: 0px 4px 10px 0px #80808030;

  &::after, &::before {
    background-color: base.$c-text;
  }
}