@use "base";

.hero {
  @extend %unhide;
  display: flex;
  flex-flow: column nowrap;
  margin: 0;
  width: 100%;
  padding: 3rem 1rem;
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: base.$c-bg-accent;
  transform-origin: 50% 0%;
  transform: scaleY(0);
  overflow-x: hidden;
}

nav.navbar + main > .hero {
  padding: 0 1rem 3rem 1rem;
}
