@use 'base';

.logos {
  @extend %unhide;
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  transform: scale(0);
  animation-delay: 1s;
  filter: brightness(5000%);

  a:not(:last-child) {
    margin-right: 1rem;
  }

  a {
    transition: transform .2s;

    &:hover {
      transform: scale(110%);
    }
  }
}