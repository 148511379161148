@use 'base';

@keyframes flyin-frames {
  to {
    transform: unset;
    opacity: 100%;
  }
}

.flyin {
  display: inline-block;
  opacity: 0.01;
  transform: translateX(5em);
  animation: .5s base.$spawn-curve 0s 1 normal forwards flyin-frames;
}