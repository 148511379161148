$c-bg: #393f41;
$c-bg-accent: #474d50;
$c-bg-content: #242729;
$c-text: #efefef;
$c-text-light: #d8e0ec;
$c-text-accent: #d8e0ec;
$c-text-accent2: #3595c6;
$c-content-border: #717171;

$spawn-curve: cubic-bezier(0.22, 0.61, 0.36, 1);

@keyframes unhide-frames {
  to {
    transform: unset;
  }
}

%unhide {
  animation: .5s 0s $spawn-curve 1 forwards unhide-frames;
}

main {
  transform-origin: 50% 0%;
  transform: none;
}

main.hide {
  transform: scaleY(0%);
  transition: transform .25s;
}

.has-accent2 {
  color: $c-text-accent2;
}

.has-white {
  color: #cae1ff;
}

.inline-block {
  display: inline-block;
}

.horizontal {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  justify-content: space-between;
  align-items: flex-start;

  a, a:visited {
    color: inherit;
    text-decoration-color: currentColor;
  }
}
