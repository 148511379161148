@use 'base';

.tag {
  display: inline-block;
  margin-right: 1rem;
  border-radius: 200000px;
  color: white;
  padding: .25rem .5rem;
  margin-bottom: .25rem;
}

.tags {
  display: flex;
  flex-flow: row wrap;
}