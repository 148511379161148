@use 'base';

html, body {
  margin: 0;
  padding: 0;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  background-color: base.$c-bg;
  color: base.$c-text;
}

*, *::after, *::before {
  box-sizing: inherit;
}
