@use 'base';

.container {
  @extend %unhide;
  margin: 1rem auto 0 auto;
  max-width: 900px;
  width: calc(100% - 2rem);
  padding: 1rem;
  background-color: base.$c-bg-content;
  transform-origin: 50% 25%;
  transform: scale(0);
  border: 1px solid base.$c-content-border;

  h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
  }

  p {
    margin: 0;
  }

  &:last-child {
    margin-bottom: 1rem;
  }
}
