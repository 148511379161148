@use 'base';

.horizontal .title, .title:has(+ :is(p, .tags)) {
  margin-bottom: .25em;
}

.title {
  color: base.$c-text;
}

.subtitle {
  margin-top: 0;
  color: base.$c-text-accent;
  font-size: 1.25em;
  font-weight: bold;
}